import { TextField } from "@mui/material"
import { useState } from "react"
import { Button, PageWrapper } from "../components"
import { useAppData } from "../hooks"


const CreateIdea = () => {
    const [text, setText] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { _createIdea } = useAppData()

    const handleSubmit = async e => {
        e.preventDefault()
        setIsSubmitting(true)
        try {
            await _createIdea(text)
        } catch(err) {

        }
        setIsSubmitting(false)
    }

    const handleChange = e => {
        const { value } = e.target
        setText(value)
    }

    return (
        <PageWrapper>
            <form onSubmit={handleSubmit}>
                <TextField
                    value={text}
                    onChange={handleChange}
                />
                <Button type='submit' disabled={isSubmitting}>Create</Button>
            </form>
        </PageWrapper>
    )
}

export default CreateIdea