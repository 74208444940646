import styles from './IdeaSpeedDatingPage.module.sass'
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Avatar, Button, PageTitle, PageWrapper } from "../../components"
import { useAppData } from "../../hooks"
import { useCallback, useEffect, useMemo, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { TextField } from '@mui/material'
import { ROUTES } from '../../helpers/constants'
import { AnimatePresence, motion } from 'framer-motion'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded'

const IdeaSpeedDatingPage = () => {
    const { getText, idea, getIdea, user, commentIdea } = useAppData()
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()

    const [status, setStatus] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [data, setData] = useState('')

    const cleanStatus = useCallback(() => setStatus(null), [setStatus])

    useEffect(() => {
        if(!idea || idea.id !== params.id) getIdea(params.id)
    }, [idea])

    const isNew = useMemo(() => params.id === 'new', [params])
    const isMy = useMemo(() => idea?.userid === user.id, [user, idea])
    const didIComment = useMemo(() => !!idea?.commentsList.find(item => item.userid === user.id), [user, idea])


    const text = getText('IdeaSpeedDatingPage')

    const handleChange = e => {
        const { value } = e.target
        setData(value)
    }

    const handleSubmit = useCallback(async e => {
        e.preventDefault()
        setIsSubmitting(true)
        try {
            const res = await commentIdea(params.id, data)
            setStatus(res)
            if(!res.error) {
                await getIdea(params.id)
            }
        } catch(err) {
            console.log(err)
        }
        setIsSubmitting(false)
    }, [setIsSubmitting, data])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <PageWrapper footerFaq={text.FAQs}>
            <PageTitle title={isNew ? text.pageNewTitle : isMy ? text.pageMyTitle : text.pageEditTitle} />
            <div className={styles.root}>
                <div className={styles.header}>
                    <ArrowBackRoundedIcon onClick={() => navigate(-1)} />
                    <p>{text.title}</p>
                    <div style={{width: 24, height: 24}} />
                </div>
                <div className={styles.ideaPast}>
                    <div className={`${styles.section} ${styles.little}`}>
                        <div className={styles.avatar}>
                            <img src='/img/neutral.svg' alt=':(' />
                        </div>
                        <div>
                            <p className={styles.title}>Main Challenge</p>
                            <p className={styles.subTitle}>{idea?.challengetext}</p>
                        </div>
                    </div>
                    <div className={`${styles.section} ${styles.little}`}>
                        <div className={styles.avatar}>
                            <img src='/img/dating.svg' alt=':)' />
                        </div>
                        <div>
                            <p className={styles.title}>Idea Speed Dating</p>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.avatar}>
                            <Avatar img={idea?.useravatar} />
                        </div>
                        <div className={styles.message}>
                            <p>{idea?.text}</p>
                        </div>
                    </div>
                    {idea?.commentsList.map(item => <div key={`comment-${item.id}`} className={styles.section}>
                        <div className={styles.avatar}>
                            <Avatar img={item.useravatar} />
                        </div>
                        <div className={styles.message}>
                            <p>{item.text}</p>
                        </div>
                    </div>)}
                </div>
                {!isMy && !didIComment && <form onSubmit={handleSubmit}>
                    <div className={styles.avatar}>
                        <Avatar img={user.avatar} me />
                    </div>
                    <div className={styles.content}>
                        <p className={styles.subTitle}>{text.textLabel}</p>
                        <TextField value={data} onChange={handleChange} multiline rows={2} placeholder='Start enter here...' />
                        <div className={styles.submit}>
                            <Button type='submit' disabled={isSubmitting || data.length === 0}>{text.buttonSave}</Button>
                            <AnimatePresence>
                            {status && <motion.div key='successor' onClick={cleanStatus} exit={{ x: -105, opacity: 0 }} initial={{ x: -105, opacity: 0 }} animate={{ x: 14, opacity: 1 }} className={`${styles.alert} ${status.error ? styles.error : styles.success}`}>
                                {status.error ? <ErrorOutlineRoundedIcon color='inherit' /> : <ThumbUpRoundedIcon color='inherit' />}
                                <p>{status.msg}</p>
                            </motion.div>}
                            </AnimatePresence>
                        </div>
                    </div>
                </form>}
                {!isMy && didIComment && <div className={styles.ideaPast2}>
                    <div className={styles.content}>
                        <p className={styles.subTitle}>{text.textLabelThanks}</p>
                        <div className={styles.submit}>
                            <Button to={ROUTES.DATING}>{text.buttonNext}</Button>
                        </div>
                    </div>
                </div>}
            </div>
        </PageWrapper>
    )

}

export default IdeaSpeedDatingPage