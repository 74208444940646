import React, { useEffect } from 'react'
import { Button, PageTitle } from '../../components'
import styles from './ComingSoonPage.module.sass'
import Countdown from 'react-countdown'
import useAppData from '../../hooks/useAppData'

const ComingSoonPage = () => {
    const { timeBeforeStart, getText, unblockApp, setPathname } = useAppData()
    const text = getText('ComingSoonPage')

    useEffect(() => {
        setPathname(window.location.pathname + 'comingsoon')
    }, [])

    const countdownRender = ({ days, formatted: { hours, minutes, seconds } }) => {
        return <p className={styles.counter}>{days} Day{days === 1 ? '' : 's'}, {hours}:{minutes}:{seconds}</p>
    }

    return (
        <>
        <PageTitle title={text.pageTitle} />
        <div className={styles.root}>
            <div className={styles.img}>
                <img src={text.imgURL} alt={text.title} />
            </div>
            <div className={styles.content}>
                <h1>{text.title}</h1>
                <h3>{text.subTitle}</h3>
                {/* <Countdown date={timeBeforeStart} renderer={countdownRender} autoStart onComplete={unblockApp} /> */}
                <div className={styles.btns}>
                    <a title={text.signupText} href={text.signupURL} target="_blank"><Button boxy><p>{text.signupText}</p></Button></a>
                    <a title={text.moreinfoText} href={text.moreinfoURL} target="_blank"><Button boxy>{text.moreinfoText}</Button></a>
                </div>
            </div>
        </div>
        </>
    )
}

export default ComingSoonPage