import React, { useEffect, useState } from 'react'
import useAppData from '../../hooks/useAppData'
import styles from './Categories.module.sass'
import { OnboardingCard, PageTitle, PageWrapper } from '../../components'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

const Categories = () => {
    const { getText, getChosenCategories, toggleTerritoryOnboarding, chosenCategories, getOnboardingCategories, categories, setPathname } = useAppData()
    const text = getText('Categories')

    useEffect(() => {
        getOnboardingCategories()
        getChosenCategories()
        setPathname(window.location.pathname)
    }, [])

    useEffect(() => {
        setSelected(prev => chosenCategories.map(item => item.id))
    }, [chosenCategories])

    const [selected, setSelected] = useState([])

    const toggleSelect = async e => { 
        try {
            const { id } = e.target
            await toggleTerritoryOnboarding(id)
        } catch(err) {
            console.log(err)
        }
        // const index = prev.findIndex(item => Number(e.target.id) === item)
        // if(index > -1) prev.splice(index, 1)
        // else prev.push(Number(e.target.id))
        // return [ ...prev ]
    }

    return (
        <PageWrapper footerFaq={text.FAQs}>
            <PageTitle title={text.pageTitle} />
            <div className={styles.root}>
                <p className={styles.subTitle}>{text.subTitle}</p>
                <p className={styles.title}>{text.title}</p>
                <div className={styles.categories}>
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 1920: 4}}
                    >
                        <Masonry gutter='16px'>
                            {categories.map((item, index) => <OnboardingCard key={`categories${index}`} content={item} selected={selected.includes(item.id)} toggleSelect={toggleSelect} />)}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        </PageWrapper>
    )
}

export default Categories