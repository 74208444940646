import React, { useState } from "react"
import { Link } from "react-router-dom"
import { ROUTES } from "../../helpers/constants"
import useAppData from "../../hooks/useAppData"
import Button from "../Button/Button"
import styles from './PageWrapper.module.sass'
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded'
import WysiwygRoundedIcon from '@mui/icons-material/WysiwygRounded'
import TopicRoundedIcon from '@mui/icons-material/TopicRounded'
import SupportRoundedIcon from '@mui/icons-material/SupportRounded'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import DynamicFormRoundedIcon from '@mui/icons-material/DynamicFormRounded'
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded'
import ModelTrainingRoundedIcon from '@mui/icons-material/ModelTrainingRounded'

const PageWrapper = ({
    backdrop = false,
    children,
    footerFaq,
    FooterAbove
}) => {
    const { logOut, getText, stage } = useAppData()

    const emailSupport = getText('emailSupport')

    const [burger, setBurger] = useState(false)

    const toggleBurger = () => setBurger(prev => !prev)

    return (
        <div className={`${styles.root} ${burger ? styles.burger : ''}`}>
            <div className={styles.mobile}>
                <Link to={ROUTES.HOME} className={styles.logo}>
                    <img src='/img/logo.svg' alt='DOBRA' />
                </Link>
                <div className={styles.burger} onClick={toggleBurger} />
            </div>
            <div className={styles.menu}>
                <Link to={ROUTES.HOME} className={styles.logo}>
                    <img src='/img/logo.svg' alt='DOBRA' />
                </Link>
                <div className={styles.create}>
                    <Button to={stage === 1 ? ROUTES.STICKERS + '/new' : ROUTES.IDEATION} boxy><DashboardCustomizeRoundedIcon color='inherit' /> Create {stage === 1 && 'Sticker'}{stage === 2 && 'Ideas'}</Button>
                </div>
                <nav className={styles.nav}>
                    <p>Public Area</p>
                    <ul>
                        {stage === 2 && <Link to={ROUTES.DATING}><li className={window.location.pathname === ROUTES.DATING ? styles.active : ''}><ModelTrainingRoundedIcon color='inherit' /><p>Idea Speed Dating</p></li></Link>}
                    </ul>
                    <p>My Workspace</p>
                    <ul>
                        <Link to={ROUTES.HOME}><li className={window.location.pathname === ROUTES.HOME ? styles.active : ''}><DashboardRoundedIcon color='inherit' /><p>Dashboard</p></li></Link>
                        {stage === 1 && <Link to={ROUTES.CATEGORIES}><li className={window.location.pathname === ROUTES.CATEGORIES ? styles.active : ''}><TopicRoundedIcon color='inherit' /><p>Topics</p></li></Link>}
                        {stage === 1 && <Link to={ROUTES.STICKERS}><li className={window.location.pathname === ROUTES.STICKERS ? styles.active : ''}><WysiwygRoundedIcon color='inherit' /><p>Stickers</p></li></Link>}
                        {stage === 2 && <Link to={ROUTES.CHALLENGES}><li className={window.location.pathname === ROUTES.CHALLENGES ? styles.active : ''}><TopicRoundedIcon color='inherit' /><p>Challenges</p></li></Link>}
                        {stage === 2 && <Link to={ROUTES.IDEATION}><li className={window.location.pathname === ROUTES.IDEATION ? styles.active : ''}><DynamicFormRoundedIcon color='inherit' /><p>Ideation</p></li></Link>}
                        {stage === 2 && <Link to={ROUTES.FAVOURITES}><li className={window.location.pathname === ROUTES.FAVOURITES ? styles.active : ''}><FavoriteRoundedIcon color='inherit' /><p>Favourite Ideas</p></li></Link>}
                        <a href={`mailto:${emailSupport}`} rel="noreferrer" target='_blank'><li><SupportRoundedIcon color='inherit' /><p>Support</p></li></a>
                        <li onClick={logOut}><LogoutRoundedIcon color='inherit' /><p>Log out</p></li>
                    </ul>
                </nav>
            </div>
            <div className={`${styles.content} ${footerFaq && footerFaq.length ? styles.withfooter : ''}`}>
                {children}
            </div>
            {footerFaq && footerFaq.length && <footer className={styles.faq}>
                {FooterAbove && <FooterAbove styles={styles} />}
                {footerFaq.map((item, index) => <div key={`footer${index}`}>
                    {styles.title && <p className={styles.title}>{item.title}</p>}
                    <p className={styles.desc}>{item.desc.split('\n')[0]}</p>
                    {item.desc.split('\n')[1] && <p className={styles.desc}>{item.desc.split('\n')[1]}</p>}
                    {item.desc.split('\n')[2] && <p className={styles.desc}>{item.desc.split('\n')[2]}</p>}
                    {item.desc.split('\n')[3] && <p className={styles.desc}>{item.desc.split('\n')[3]}</p>}                                        
                </div>)}
            </footer>}
        </div>
    )
}

export default PageWrapper