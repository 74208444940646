import { useCallback, useState } from 'react'
import { useModalData } from '../../hooks'
import Button from '../Button/Button'
import styles from './Modal.module.sass'
import AppsIcon from '@mui/icons-material/Apps'
import { TextField } from '@mui/material'

const Modal = () => {
    const { open, type, data, clearModal } = useModalData()

    const [inputVal, setInputVal] = useState('')

    const handleInputChange = useCallback(e => {
        const { value } = e.target
        setInputVal(value)
    }, [])

    const handleCancel = useCallback(async () => {
        if(data.onCancel && typeof data.onCancel === 'function') await data.onCancel()
        clearModal()
    }, [clearModal, data])

    const handleConfirm = useCallback(async () => {
        try {
            if(type === 'delete' && inputVal !== 'delete') return
            if(data.onConfirm && typeof data.onConfirm === 'function') await data.onConfirm()
            clearModal()
            setInputVal('')
        } catch(err) {
            if(data.errorHandler) data.errorHandler(err)
        }
    }, [clearModal, data, type, inputVal])

    return (
        <div className={`${styles.root} ${styles[type]} ${open ? styles.open : ''}`}>
            <div className={styles.card}>
                <div className={styles.content}>
                    {type === 'delete' && <AppsIcon color='inherit' sx={{ alignSelf: 'center' }} />}
                    <p className={styles.title}>{data.title}</p>
                    <p className={styles.subTitle}>{data.subTitle}</p>
                    {type === 'delete' && <TextField
                        className={styles.deleteInput}
                        value={inputVal}
                        onChange={handleInputChange}
                        InputLabelProps={{ shrink: true }}
                        placeholder='Enter delete to confirm'
                        label='delete *'
                        error={true}
                    />}
                </div>
                <div className={styles.buttons}>
                    {!data.hideCancel && <Button color='transparent' onClick={handleCancel}>{data.cancel}</Button>}
                    <Button color='transparent' onClick={handleConfirm}>{data.confirm}</Button>
                </div>
            </div>
        </div>
    )
}

export default Modal