import styles from './Ideation.module.sass'
import { Button, FullPageLoader, PageTitle, PageWrapper, OnboardingCard, StatCard } from '../../components'
import { useAppData, useModalData } from '../../hooks'
import parse from 'html-react-parser'
import { TextField } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Countdown from 'react-countdown'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../helpers/constants'

const STAT_CARDS = ['currentmethodology', 'ideas', 'ideasfavorite', 'speeddatings']

const Ideation = () => {
    const {
        getText,
        methodology,
        stage2status,
        startMethodolody,
        continueMethodology,
        quitMethodology,
        completeMethodology,
        cleanMethodology,
        updateStatistics,
        getSecondStageStatus
    } = useAppData()
    const { openModal } = useModalData()
    const navigate = useNavigate()
    const text = getText('Ideation')

    const [isCompleting, setIsCompleting] = useState(false)
    const [data, setData] = useState([])
    const [input, setInput] = useState('')

    const timeBeforeEnd = useMemo(() => methodology && methodology.startedAt && (methodology.startedAt + methodology.timelimit * 60000), [methodology])

    const handleChange = e => {
        const { value } = e.target
        setInput(value)
    }

    const toggleSelect = e => setData(prev => { 
        const { id } = e.target
        const index = prev.findIndex(item => id === item.text)
        prev[index].isFavorite = !prev[index].isFavorite
        return [ ...prev ]
    })

    useEffect(() => {
        updateStatistics()
        getSecondStageStatus()
    }, [])

    useEffect(() => {
        let data
        const _data = localStorage.getItem('ideation')
        if(_data) data = JSON.parse(_data)
        if(data && methodology && data.id === methodology.id) startMethodolody()
    }, [methodology])

    useEffect(() => {
        if(!methodology?.step && methodology && data.length >= (methodology.ideasperround * methodology.roundsList.length)) {
            triggerAlert('complete')
        }
    }, [data])

    const handleSubmit = useCallback(e => {
        e.preventDefault()
        try {
            setData(prev => [...prev, {
                text: input,
                isFavorite: false
            }])
            document.getElementById('maintext').focus()
            setInput('')
        } catch(err) {
            console.log(err)
        }
    }, [setData, input])

    const handleCompleteMethodology = async () => {
        setIsCompleting(true)
        await completeMethodology(data)
        await updateStatistics()
        const stage2status = await getSecondStageStatus()
        openModal('alert', {
            ...(stage2status.isspeeddatingenabled ? text.modal.endedDating : text.modal.ended),
            hideCancel: !stage2status.isspeeddatingenabled,
            onCancel: async () => {
                setData([])
                cleanMethodology()
                navigate(ROUTES.DATING)
            },
            onConfirm: async () => {
                setData([])
                cleanMethodology()
            }
        })
        setIsCompleting(false)
    }

    const countdownRender = ({ formatted: { minutes, seconds } }) => (
        <p className={styles.counter}>{minutes}:{seconds}</p>
    )
    
    const triggerAlert = type => {
        // time | complete | quit
        openModal('alert', {
            ...text.modal[type],
            hideCancel: true,
            onConfirm: () => {
                setData(prev => [...new Map(prev.map(v => [v.text, v])).values()].filter(item => item.text))
                type !== 'quit' && continueMethodology()
            }
        })
    }

    const handleQuit = () => {
        openModal('alert', {
            ...text.modal.quit,
            onConfirm: () => {
                setData([])
                quitMethodology()
            }
        })
    }

    useEffect(() => {
        function onBeforeUnload(e) {
            e.preventDefault()
            e.returnValue = 'Are you sure?'
            return 'Are you sure?'
            delete e['returnValue']
        }
        
        if(typeof(methodology?.step) === 'number') window.addEventListener('beforeunload', onBeforeUnload)
        else window.removeEventListener('beforeunload', onBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', onBeforeUnload)
            setData([])
            quitMethodology()
        }
    }, [methodology])

    const isDisabledToEnd = useMemo(() => data.filter(item => item.isFavorite).length < (methodology?.favoriteideascount || 1), [data, methodology])

    if(!stage2status) return <FullPageLoader />

    if(!timeBeforeEnd) return (
        <PageWrapper>
            {/* <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                background: 'rgba(0,0,0,.5)',
                maxWidth: 400,
                zIndex: 100,
                fontSize: 14,
                padding: 10,
                color: '#fff',
                wordBreak: 'break-all',
                height: 'min-content'
            }}>{JSON.stringify(methodology)}</div> */}
            <PageTitle title={text.pageTitle} />
            <div className={styles.root}>
                <p className={styles.subTitle}>{text.subTitle}</p>
                <p className={styles.title}>{text.title}</p>
                <div className={styles.progress}>
                    <p>My progress</p>
                    <div>
                        {STAT_CARDS.map(item => <StatCard key={item} type={item} />)}
                    </div>
                </div>
                <div className={styles.ideation}>
                    <h1>{text.ideation[Number(!!!stage2status.currentmethodologyid)].title[Number(!!(stage2status.currentmethodology > 1 && stage2status.currentmethodologyid))]}</h1>
                    <p>{text.ideation[Number(!!!stage2status.currentmethodologyid)].subTitle[Number(!!(stage2status.currentmethodology > 1 && stage2status.currentmethodologyid))]}</p>
                    <Button onClick={stage2status.currentmethodologyid && startMethodolody} to={!stage2status.currentmethodologyid && ROUTES.DATING} boxy>{text.ideation[Number(!!!stage2status.currentmethodologyid)].button[Number(!!(stage2status.currentmethodology > 1 && stage2status.currentmethodologyid))]}</Button>
                </div>
            </div>
        </PageWrapper>
    )
    
    if(methodology.step === 0) return (
        <div className={`${styles.sessionWrapper} ${styles.withHover}`}>
            <div className={styles.menu}>
                <div className={styles.close} onClick={handleQuit}>
                    <img src='/img/icons/close.svg' alt='x' />
                </div>
            </div>
            <div className={styles.content}>
                <div>
                    <p className={styles.label}>Main Challenge</p>
                    <p className={styles.challenge}>{stage2status.challenge.text}</p>
                    <div className={styles.ideation} style={{ background: `${methodology.color ? methodology.color : '#FFEDD5'}`}} >                        
                        <p className={styles.label}>Resolve the challenge by</p>
                        <p className={styles.description}>{parse(methodology.roundsList[data.length < methodology.ideasperround ? 0 : ~~(data.length / methodology.ideasperround)]?.description || '')}</p>
                        <p className={styles.title}>{parse(methodology.roundsList[data.length < methodology.ideasperround ? 0 : ~~(data.length / methodology.ideasperround)]?.title || '')}</p>
                    </div>
                    <div className={styles.progressBar}>
                        <p>Ideas in round {data.length < methodology.ideasperround ? data.length : data.length - (~~(data.length / methodology.ideasperround) * methodology.ideasperround)}/{methodology.ideasperround}</p>
                        <div>
                            <div style={{ width: `${((data.length < methodology.ideasperround ? data.length : data.length - (~~(data.length / methodology.ideasperround) * methodology.ideasperround)) / methodology.ideasperround) * 100}%` }} />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.textarea}>
                            <TextField id='maintext' autoFocus error={false} value={input} onChange={handleChange} multiline rows={3} placeholder='Put your idea here' />
                            <div className={styles.counter} style={{ color: input.length > 400 ? '#D32F2F' : '#94A3B8' }}>{('000' + input.length).slice(-3)}/400</div>
                        </div>
                        <Button type='submit' disabled={!!!input.length}>Save changes</Button>
                    </form>
                </div>
            </div>
            <footer className={styles.info}>
                <div className={styles.timer}>
                    <p className={styles.label}>Time Left</p>
                    <Countdown date={timeBeforeEnd} renderer={countdownRender} autoStart onComplete={() => triggerAlert('time')} />
                </div>
                <div className={styles.counters}>
                    <div>
                        <p className={styles.label}>Round</p>
                        <h3>{data.length < methodology.ideasperround ? 1 : ~~(data.length / methodology.ideasperround) + 1}/{methodology.roundsList?.length || 0}</h3>
                    </div>
                    <div>
                        <p className={styles.label}>Ideas in round</p>
                        <h3>{data.length < methodology.ideasperround ? data.length : data.length - (~~(data.length / methodology.ideasperround) * methodology.ideasperround)}/{methodology.ideasperround}</h3>
                    </div>
                </div>
                <div className={styles.title}>
                    <p className={styles.label}>Methodology</p>
                    <h2>{methodology.title}</h2>
                </div>
                <div className={styles.instructions}>
                    <p className={styles.label}>Instructions</p>
                    <p className={styles.text}>{methodology.rules}</p>
                </div>
                {methodology.example && <div className={styles.example}>
                    {parse(methodology.example)}
                </div>}
            </footer>
        </div>
    )
    return (
        <div className={`${styles.sessionWrapper} ${styles.bigFooter}`}>
            <div className={styles.menu}>
                <div className={styles.close} onClick={handleQuit}>
                    <img src='/img/icons/close.svg' alt='x' />
                </div>
            </div>
            <div className={styles.content}>
                <div>
                    <p className={styles.label}>Main Challenge</p>
                    <p className={styles.challenge}>{stage2status.challenge.text}</p>
                    <div className={styles.ideas}>
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{350: 1, 750: 2, 1920: 4}}
                        >
                            <Masonry gutter='16px'>
                                {data.map((item, index) => <OnboardingCard selected={item.isFavorite} accessor='text' toggleSelect={toggleSelect} key={`cardstick${index}`} variant='sticker' content={item} />)}
                            </Masonry>
                        </ResponsiveMasonry>
                    </div>
                </div>
            </div>
            <footer className={styles.info}>
                <div>
                    <p className={styles.title}>{text.end.title}</p>
                    <p className={styles.desc}>{text.end.subTitle}</p>
                    <p className={styles.title}>{text.end.title2}</p>
                    <p className={styles.desc}>{text.end.subTitle2}</p>
                    <p className={styles.helperText}>{text.end.helperText.replace('{NUM}', methodology?.favoriteideascount || 1)}</p>
                    <Button className={styles.btn} onClick={handleCompleteMethodology} disabled={isCompleting || isDisabledToEnd}>{text.end.button}</Button>
                </div>
            </footer>
        </div>
    )
}

export default Ideation