import { useCallback, useContext, useEffect } from "react"
import { AppDataContext, client, firebaseApp } from "../Main"
import rpc from 'ideachain_grpc'
import textData from "../textData"
import { json } from "react-router-dom"
import { Empty as EmptyPB } from 'google-protobuf/google/protobuf/empty_pb'
import { simplifyStickersList, simplifyTerritoryList } from "../helpers/simplifiers"

const useAppData = () => {
    const { state: [appData, setAppData], firebaseUser } = useContext(AppDataContext)

    const handleError = useCallback(async err => {
        console.log(err, err.response)
        if(err.code === 16) {
            firebaseApp.auth().signOut()
        }
        if(err.code === 13) {
            // throw new Response("", { status: 500 })
        }
    }, [])

    const getStatus = useCallback(async () => {
        try {
            const res = await client.getStatus(new EmptyPB())
            const serviceDisabledUntilTS = res.getStarttime()
            const stage = res.getStage()
            setAppData(prev => ({...prev, timeBeforeStart: serviceDisabledUntilTS * 1000, isAppBlocked: (serviceDisabledUntilTS * 1000) > Date.now(), stage }))
        } catch(err) {
            handleError(err)

        }
    }, [setAppData, handleError])

    const unblockApp = useCallback(() => setAppData(prev => ({...prev, isAppBlocked: false})), [setAppData, handleError])

    const continueRegisterStep = useCallback(async () => {
        try {
            const authRequest = new rpc.pb.AuthTokenRequest()
            authRequest.setAuthtoken(await firebaseUser.getIdToken())
            const res = await client.incrementRegisterStep(authRequest)
            const registerStep = res.getRegisterstep()
            setAppData(prev => ({...prev, user: { ...prev.user, registerStep }}))
        } catch(err) {
            handleError(err)

        }
    }, [setAppData, firebaseUser, handleError])

    const getText = useCallback(key => {
        try {
            if(key in textData) return textData[key]
        } catch(err) {
            handleError(err)
            return null
        }
    }, [handleError])

    const logOut = useCallback(async () => {
        try {
            firebaseApp.auth().signOut()
        } catch(err) {
            handleError(err)

        }
    }, [handleError])

    const getOnboardingCategories = useCallback(async () => {
        if(appData.categories.length) return null
        try {
            const req = new rpc.pb.AuthTokenRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            const res = await client.territoryListOnboarding(req)
            const onboardings = simplifyTerritoryList(res.getTerritoriesList())
            setAppData(prev => ({...prev, categories: [ ...onboardings ]}))
        } catch(err) {
            handleError(err)

        }
    }, [firebaseUser, setAppData, appData.categories, handleError])

    const getChosenCategories = useCallback(async () => {
        try {
            const req = new rpc.pb.AuthTokenRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            const res = await client.territoryListSelected(req)
            const onboardings = simplifyTerritoryList(res.getTerritoriesList())
            setAppData(prev => ({...prev, chosenCategories: [ ...onboardings ]}))
        } catch(err) {
            handleError(err)

        }
    }, [appData.chosenCategories, setAppData, firebaseUser, handleError])

    const addTerritoryOnboarding = useCallback(async id => {
        try {
            const req = new rpc.pb.TerritoryGetRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setId(id)
            const res = await client.territoryAddSelected(req)
            const categories = simplifyTerritoryList(res.getTerritoriesList())
            setAppData(prev => ({ ...prev, chosenCategories: [ ...categories ] }))
        } catch(err) {
            handleError(err)

        }
    }, [setAppData, firebaseUser, handleError])

    const toggleTerritoryOnboarding = useCallback(async id => {
        try {
            const req = new rpc.pb.TerritoryGetRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setId(id)
            const res = await client.territoryToggleSelected(req)
            const categories = simplifyTerritoryList(res.getTerritoriesList())
            setAppData(prev => ({ ...prev, chosenCategories: [ ...categories ] }))
        } catch(err) {
            handleError(err)

        }
    }, [setAppData, firebaseUser, handleError])

    const getOnboardingStickers = useCallback(async () => {
        try {
            const req = new rpc.pb.AuthTokenRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            const res = await client.stickerListOnboarding(req)
            const stickers = simplifyStickersList(res.getStickersList())
            const users = simplifyStickersList(res.getUsersList())
            return { stickers, users }
        } catch(err) {
            handleError(err)

        }
    }, [firebaseUser, handleError])

    const getMyStickers = useCallback(async () => {
        try {
            const req = new rpc.pb.AuthTokenRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            const res = await client.stickerListMy(req)
            const stickers = simplifyStickersList(res.getStickersList())
            // const users = simplifyStickersList(res.getUsersList())
            setAppData(prev => ({ ...prev, stickers: [ ...stickers ] }))
        } catch(err) {
            handleError(err)

        }
    }, [firebaseUser, setAppData, handleError])

    const getSticker = useCallback(async id => {
        try {
            const req = new rpc.pb.StickerGetRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setId(id)
            const res = await client.stickerGet(req)
            const data = res.toObject()
            return data
        } catch(err) {
            handleError(err)

        }
    }, [firebaseUser, handleError])

    const createSticker = useCallback(async ({ category, type, title, text }) => {
        let errors = {}
        if(!category) errors = {...errors, category: 'Category can\'t be blank!' }
        if(!type) errors = {...errors, type: 'Type can\'t be blank!' }
        if(!title) errors = {...errors, title: 'Title can\'t be blank!' }
        if(title.length > 70) errors = {...errors, title: 'Title can\'t be more than 70 chars!' }
        if(!text) errors = {...errors, text: 'Text can\'t be blank!' }
        if(text.length > 400) errors = {...errors, text: 'Text can\'t be more than 400 chars!' }
        if(Object.keys(errors).length > 0) return {error: true, errors}
        try {
            const req = new rpc.pb.StickerCreateRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setTerritoryid(category)
            req.setType(type)
            req.setTitle(title)
            req.setText(text)
            await client.stickerCreate(req)
            return { error: false, msg: 'Saved!' }
        } catch(err) {
            handleError(err)

        }
    }, [firebaseUser, handleError])

    const updateSticker = useCallback(async ({ category, type, title, text, id }) => {
        if(!id) return { error: true, cause: null, msg: 'Error!' }
        let errors = {}
        if(!category) errors = {...errors, category: 'Category can\'t be blank!' }
        if(!type) errors = {...errors, type: 'Type can\'t be blank!' }
        if(!title) errors = {...errors, title: 'Title can\'t be blank!' }
        if(title.length > 70) errors = {...errors, title: 'Title can\'t be more than 70 chars!' }
        if(!text) errors = {...errors, text: 'Text can\'t be blank!' }
        if(text.length > 400) errors = {...errors, text: 'Text can\'t be more than 400 chars!' }
        if(Object.keys(errors).length > 0) return {error: true, errors}
        try {
            const req = new rpc.pb.StickerUpdateRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setId(id)
            req.setTerritoryid(category)
            req.setType(type)
            req.setTitle(title)
            req.setText(text)
            await client.stickerUpdate(req)
            return { error: false, msg: 'Saved!' }
        } catch(err) {
            handleError(err)

        }
    }, [firebaseUser, handleError])

    const getSecondStageStatus = useCallback(async unload => {
        try {
            const req = new rpc.pb.AuthTokenRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            const res = await client.getSecondStageStatus(req)
            const data = res.toObject()
            setAppData(prev => ({...prev, stage2status: data, isAppLoading: false}))
            return data
        } catch(err) {
            handleError(err)
        }
    }, [firebaseUser, setAppData, handleError])

    const getChallengesList = useCallback(async () => {
        try {
            const req = new rpc.pb.AuthTokenRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            const res = await client.challengeGetList(req)
            let challenges = res.toObject().challengesList
            if(appData.user.challengeid && challenges[0].id !== appData.user.challengeid) {
                let index = challenges.findIndex(item => item.id === appData.user.challengeid)
                if(index > -1) [challenges[0], challenges[index]] = [challenges[index], challenges[0]]
            }
            setAppData(prev => ({ ...prev, challenges: [ ...challenges ] }))
        } catch(err) {
            handleError(err)

        }
    }, [firebaseUser, setAppData, appData, handleError])

    const setupChallenge = useCallback(async id => {
        try {
            const req = new rpc.pb.ObjectIdRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setId(id)
            const res = await client.challengeSetup(req)
            const challengeid = res.toObject().challenge.id
            setAppData(prev => {
                if(prev.challenges.length > 0 && prev.challenges[0].id !== challengeid) {
                    let index = prev.challenges.findIndex(item => item.id === challengeid)
                    if(index > -1) [prev.challenges[0], prev.challenges[index]] = [prev.challenges[index], prev.challenges[0]]
                }
                return {
                    ...prev,
                    user: {
                        ...prev.user,
                        challengeid
                    }
                }
            })
        } catch(err) {
            handleError(err)
            throw err
        }
    }, [firebaseUser, setAppData, handleError])

    const getMethodology = useCallback(async id => {
        try {
            const req = new rpc.pb.ObjectIdRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setId(id)
            const res = await client.methodologyGet(req)

            // console.log(res.toObject())

            setAppData(prev => ({...prev, methodology: {...res.toObject(), startedAt: null}}))
        } catch(err) {
            handleError(err)
        }
    }, [setAppData, firebaseUser, handleError])

    const startMethodolody = useCallback(async () => {
        try {
            if(appData.methodology && appData.methodology.id && !appData.methodology.startedAt) {
                let data, startedAt
                let step = 0
                const _data = localStorage.getItem('ideation')
                if(_data) data = JSON.parse(_data)
                if(data && data.id && data.startedAt && appData.methodology.id === data.id) {
                    startedAt = data.startedAt
                    if(data.step) step = data.step
                }
                else {
                    startedAt = Date.now()
                    localStorage.setItem('ideation', JSON.stringify({
                        id: appData.methodology.id,
                        startedAt
                    }))
                }
                setAppData(prev => ({ ...prev, methodology: { ...prev.methodology, startedAt, step } }))
            }
        } catch(err) {
            handleError(err)
        }
    }, [setAppData, appData.methodology, handleError])

    const quitMethodology = useCallback(() => {
        localStorage.removeItem('ideation')
        setAppData(prev => ({ ...prev, methodology: null }))
    }, [setAppData])

    const continueMethodology = useCallback(async () => {
        try {
            if(appData.methodology && appData.methodology.id) {
                localStorage.setItem('ideation', JSON.stringify({
                    id: appData.methodology.id,
                    startedAt: appData.methodology.startedAt
                }))
                setAppData(prev => ({ ...prev, methodology: { ...prev.methodology, step: 1 } }))
            }
        } catch(err) {
            handleError(err)
        }
    }, [setAppData, appData.methodology, handleError])

    const completeMethodology = useCallback(async ideas => {
        try {
            let list = []
            ideas.forEach(_idea => {
                if(_idea.text) {
                    const idea = new rpc.pb.NewIdea()
                    idea.setIsfavorite(_idea.isFavorite)
                    idea.setText(_idea.text)
                    list.push(idea)
                }
            })
            const req = new rpc.pb.CompleteMethodologyRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setMethodologyid(appData.stage2status.currentmethodologyid)
            req.setIdeasList(list)
            const res = await client.methodologyComplete(req)
            localStorage.removeItem('ideation')
            setAppData(prev => ({...prev, stage2status: res.toObject()}))
        } catch(err) {
            handleError(err)
        }
    }, [firebaseUser, appData.stage2status, setAppData, handleError])

    const cleanMethodology = useCallback(() => {
        setAppData(prev => ({...prev, methodology: null}))
    }, [setAppData])

    const getIdeas = useCallback(async (filter = 0, isMy = false) => { // all
        //1 SameAsMyChallenge | 2 NotAsMyChallenge | 3 WithComments | 4 WithoutComments | 5 MoreThanOneComments
        try {
            const req = new rpc.pb.IdeaListRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setFilter(filter)
            req.setIsmy(isMy)
            const res = await client.ideaGetFavoriteList(req)
            setAppData(prev => ({...prev, [isMy ? 'myFavIdeas' : 'ideas']: res.toObject().ideasList}))
        } catch(err) {
            handleError(err)

        }
    }, [firebaseUser, setAppData, handleError])

    const getIdea = useCallback(async id => {
        try {
            const req = new rpc.pb.ObjectIdRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setId(id)
            const res = await client.ideaGet(req)
            setAppData(prev => ({...prev, idea: res.toObject()}))
        } catch(err) {
            handleError(err)

        }
    }, [firebaseUser, setAppData, handleError])

    const setIdea = useCallback(idea => {
        setAppData(prev => ({...prev, idea}))
    }, [setAppData, handleError])

    const commentIdea = useCallback(async (id, text) => {
        try {
            if(!text || text.length < 1) return { error: true, msg: 'I lost your text :(' }
            const req = new rpc.pb.IdeaCommentAddRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setIdeaid(id)
            req.setText(text)
            const res = await client.ideaCommentAdd(req)
            return { error: false, msg: 'Saved!' }
        } catch(err) {
            handleError(err)
            return {
                error: true,
                msg: err.message
            }
        }
    }, [firebaseUser, handleError])

    const _createIdea = useCallback(async text => {
        try {
            const idea = new rpc.pb.NewIdea()
            idea.setIsfavorite(true)
            idea.setText(text)
            const req = new rpc.pb.CompleteMethodologyRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            req.setMethodologyid(appData.stage2status.currentmethodologyid)
            req.setIdeasList([idea, handleError])
            const res = await client.methodologyComplete(req)
        } catch(err) {
            handleError(err)

        }
    }, [firebaseUser, appData, handleError])

    const updateStatistics = useCallback(async () => {
        try {
            const req = new rpc.pb.AuthTokenRequest()
            req.setAuthtoken(await firebaseUser.getIdToken())
            const res = await client.authUser(req)
            const { counters } = res.toObject()
            setAppData(prev => ({
                ...prev,
                user: {
                    ...prev.user,
                    counters
                }
              }))
        } catch(err) {
            handleError(err)
        }
    }, [firebaseUser, setAppData, handleError])

    const setPathname = pathname => setAppData(prev => ({...prev, pathname}))

    useEffect(() => console.log(appData), [appData])

    return {
        ...appData,
        getText,
        getStatus,
        unblockApp,
        continueRegisterStep,
        getOnboardingCategories,
        getChosenCategories,
        addTerritoryOnboarding,
        toggleTerritoryOnboarding,
        getOnboardingStickers,
        getMyStickers,
        getSticker,
        createSticker,
        updateSticker,
        setPathname,
        getSecondStageStatus,
        getChallengesList,
        setupChallenge,
        getMethodology,
        startMethodolody,
        quitMethodology,
        continueMethodology,
        completeMethodology,
        cleanMethodology,
        getIdeas,
        getIdea,
        setIdea,
        commentIdea,
        logOut,
        updateStatistics,
        _createIdea
    }
}

export default useAppData
