import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './StickerPage.module.sass'
import { Button, PageTitle, PageWrapper } from '../../components'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useAppData from '../../hooks/useAppData'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { InputLabel, TextField } from '@mui/material'
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import { ROUTES, STICKER_TYPES, STICKER_TYPES_VALUES } from '../../helpers/constants'
import { AnimatePresence, motion } from 'framer-motion'

const StickerPage = () => {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const { getText, chosenCategories, getChosenCategories, createSticker, getSticker, updateSticker, setPathname } = useAppData()
    const isNew = useMemo(() => params.id === 'new', [params])
    const text = getText('StickerPage')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [status, setStatus] = useState(null)
    const [data, setData] = useState({
        category: location.state?.chosenCategory || "",
        type: null,
        title: '',
        text: ''
    })

    useEffect(() => {
        const fetchSticker = async () => {
            try {
                const { territoryid, title, text, type, id } = await getSticker(params.id)
                setData(prev => ({
                    ...prev,
                    category: territoryid,
                    title,
                    text,
                    type,
                    id
                }))
            } catch(err) {
                console.log(err)
            }
        }
        if(params.id && params.id !== 'new') fetchSticker()
    }, [params.id, getSticker])

    const cleanStatus = useCallback(name => setStatus(prev => {
        if(!name) return null
        if(prev.errors[name]) delete prev.errors[name]
        return { ...prev }
    }), [setStatus])

    useEffect(() => {
        getChosenCategories()
    }, [])

    const delay = ms => new Promise(res => setTimeout(res, ms))

    const handleSubmit = async e => {
        e.preventDefault()
        cleanStatus()
        setIsSubmitting(true)
        try {
            const complete = async () => isNew ? await createSticker(data) : await updateSticker(data)
            const res = await complete()
            setStatus(res)
            if(!res.error) {
                await delay(2000)
                navigate(ROUTES.STICKERS)
            }
            // navigate(ROUTES.STICKERS)
        } catch(err) {
            console.log(err)
        }
        setIsSubmitting(false)
    }

    const handleChange = e => {
        const { value, name } = e.target
        if(status?.errors[name]) cleanStatus(name)
        setData(prev => ({...prev, [name]: value}))
    }

    const handleChangeType = e => {
        const { id } = e.target
        if(status?.errors['type']) cleanStatus('type')
        setData(prev => ({...prev, type: Number(id)}))
    }

    useEffect(() => {
        setPathname(window.location.pathname)
    }, [])


    const Footer = ({ styles: _styles }) => <div>
        <p className={_styles.title}>{text.Footer.title}</p>
        <p className={_styles.desc}>{text.Footer.desc}</p>
        <Button to={`${ROUTES.STICKERS}/examples`}>{text.Footer.btn}</Button>
    </div>

    return (
        <PageWrapper footerFaq={text.FAQs} FooterAbove={Footer}>
            <PageTitle title={isNew ? text.pageNewTitle : text.pageEditTitle} />
            <div className={styles.root}>
                <div className={styles.header}>
                    <ArrowBackRoundedIcon onClick={() => navigate(ROUTES.STICKERS)} />
                    <p>{isNew ? 'Add ' : 'Edit '}Sticker</p>
                    <div style={{width: 24, height: 24}} />
                </div>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor='category'>{text.categoryLabel}</label>
                        <FormControl fullWidth error={status?.errors?.category} disabled={!isNew}>
                            <InputLabel id='category-label' placeholder='Select option'>Topic</InputLabel>
                            <Select
                                name='category'
                                id='category'
                                label='Category'
                                labelId='category-label'
                                value={data.category}
                                onChange={handleChange}
                            >
                                {chosenCategories.map((item, index) => <MenuItem key={`sticker-categories${index}`} value={item.id}>{item.title}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    <div>
                        <label>{text.typeLabel}</label>
                        <div className={styles.types}>
                            {STICKER_TYPES.map(item => <div key={`stickerpage-${item}`} onClick={handleChangeType} id={STICKER_TYPES_VALUES[item]} className={`${status?.errors?.type ? styles.error : ''} ${data.type === STICKER_TYPES_VALUES[item] ? styles.active : ''}`} name='type'>{item}</div>)}
                        </div>
                    </div>
                    <label>{text.textLabel}</label>
                    <div>
                        <TextField error={status?.errors?.title} id='title' name='title' value={data.title} onChange={handleChange} label='Please, sum it up in a few' />
                    </div>
                    <div className={styles.textarea}>
                        <TextField error={status?.errors?.text} id='text' name='text' value={data.text} onChange={handleChange} multiline rows={5} placeholder='What facts or figures in this topic struck you?  = fact 
Share your interesting observations on the topic.  = insight
Recall a typical or an unusual life occasion related to this topic. = story' />
                        <div className={styles.counter} style={{ color: data.text.length > 400 ? '#D32F2F' : '#94A3B8' }}>{('000' + data.text.length).slice(-3)}/400</div>
                    </div>
                    <div className={styles.submit}>
                        <Button type='submit' disabled={isSubmitting}>{isNew ? 'Create' : 'Save Changes'}</Button>
                        <AnimatePresence>
                        {status?.error ? Object.keys(status.errors).map(item => <motion.div key={`successor${item}`} exit={{ x: -105, opacity: 0 }} initial={{ x: -105, opacity: 0 }} animate={{ x: 0, opacity: 1 }} className={`${styles.alert} ${styles.error}`}>
                            <ErrorOutlineRoundedIcon color='inherit' />
                            <p>{status.errors[item]}</p>
                        </motion.div>) : status && status.msg && <motion.div key={`successor`} onClick={cleanStatus} exit={{ x: -105, opacity: 0 }} initial={{ x: -105, opacity: 0 }} animate={{ x: 0, opacity: 1 }} className={`${styles.alert} ${styles.success}`}>
                            <ThumbUpRoundedIcon color='inherit' />
                            <p>{status.msg}</p>
                        </motion.div>}
                        </AnimatePresence>
                    </div>
                </form>
            </div>
        </PageWrapper>
    )
}

export default StickerPage