import React from "react"
import styles from './Button.module.sass'
import MuiButton from '@mui/material/Button'
import { Link } from "react-router-dom"

const Button = ({
    onClick = () => {},
    type = 'button',
    disabled = false,
    variant = 'contained',
    color = 'blue',
    boxy = false,
    className,
    id,
    to,
    rel,
    target,
    state = {},
    children
}) => {
    if(to) return (
        <Link to={to} rel={rel} target={target} state={state} className={`${styles.root} ${styles[color]} ${className} ${boxy ? styles.boxy : ''}`}>
            <MuiButton id={id} disableElevation variant={variant} disabled={disabled} type={type} onClick={onClick}>
                {children}
            </MuiButton>
        </Link>
    )

    return (
        <div className={`${styles.root} ${styles[color]} ${className} ${boxy ? styles.boxy : ''}`}>
            <MuiButton id={id} disableElevation variant={variant} disabled={disabled} type={type} onClick={onClick}>
                {children}
            </MuiButton>
        </div>
    )
}

export default Button