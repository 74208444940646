import React, { createContext, useEffect, useState } from 'react'
import { initialAppDataState, initialModalDataState } from './helpers/initialState'
import App from './App'
import rpc from 'ideachain_grpc'
import { Empty as EmptyPB } from 'google-protobuf/google/protobuf/empty_pb'

export const AppDataContext = createContext()
export const ModalDataContext = createContext()

export const firebaseApp = window.firebase.app()
export const baseUrl = window.location.protocol + "//" + window.location.host
export const uiConfig = {
  callbacks: {
  },
  signInFlow: 'popup',
  signInSuccessUrl: window.location.href,
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    {
      provider: window.firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Facebook',
      iconUrl: '/img/icons/facebook.svg'
    },
    {
      provider: window.firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Twitter',
      iconUrl: '/img/icons/twitter.svg'
    },
    {
      provider: window.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      fullLabel: 'Continue with Google',
    }
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    // firebase.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  tosUrl: 'https://dobra.world/terms-of-service/',
  privacyPolicyUrl: 'https://dobra.world/privacy-cookie-policy/'
}
let grpcServerUri = 'https://ideachain.appspot.com'
if(window.location.hostname === "127.0.0.1" || window.location.hostname === "localhost") {
  grpcServerUri = 'http://localhost:8080'
} else if(window.location.hostname === "ideachain.dobra.world"){
  grpcServerUri = 'https://ideachain-prod.appspot.com';
}

export const client = new rpc.BasePromiseClient(grpcServerUri, null, {})

function Main() {
  const [appData, setAppData] = useState(initialAppDataState)
  const [modalData, setModalData] = useState(initialModalDataState)
  const [firebaseUser, setFirebaseUser] = useState(null)

  useEffect(() => {
    const authFunction = async stage => {
      try {
        firebaseApp.auth().onAuthStateChanged(async user => {
          if(user) {
            setFirebaseUser(user)
            const authRequest = new rpc.pb.AuthTokenRequest()
            authRequest.setAuthtoken(await user.getIdToken())
            const res = await client.authUser(authRequest)
            const userDB = res.toObject()
            setAppData(prev => ({
              ...prev,
              isAppLoading: stage === 2,
              firebaseUiStarted: false,
              user: {
                displayName: user.fullName,
                email: user.email,
                emailVerified: user.emailVerified,
                photoURL: user.photoURL,
                id: user.uid,
                phoneNumber: user.phoneNumber,
                providerData: user.providerData,
                territories: user.territories,
                ...userDB,
                registerStep: userDB.registerstep
              }
            }))
          } else {
            setAppData(prev => ({...prev, isAppLoading: false, firebaseUiStarted: true}))
          }
        })
      } catch(err) {
        console.log(err)
        setAppData(prev => ({...prev, isAppLoading: false}))
      }
    }
    const getStatus = async () => {
      try {
        const res = await client.getStatus(new EmptyPB())
        const serviceDisabledUntilTS = res.getStarttime()
        const stage = res.getStage()
        setAppData(prev => ({...prev, timeBeforeStart: serviceDisabledUntilTS * 1000, isAppBlocked: (serviceDisabledUntilTS * 1000) > Date.now(), stage }))
        return stage
      } catch(err) {
        console.log(err)
      }
    }
    getStatus().then(stage => {
      authFunction(stage)
    })
  }, [])

  return (
    <AppDataContext.Provider value={{ state: [appData, setAppData], firebaseUser }}>
      <ModalDataContext.Provider value={[modalData, setModalData]}>
        <App />
      </ModalDataContext.Provider>
    </AppDataContext.Provider>
  )
}

export default Main
