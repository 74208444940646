import styles from './FavouriteIdeas.module.sass'
import { IdeaCard, PageWrapper, PageTitle, EmptyList } from "../../components"
import { useAppData } from '../../hooks'
import { IDEAS_FILTER_VALUES, MY_IDEAS_FILTERS, ROUTES } from '../../helpers/constants'
import { useEffect, useState } from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

const FavouriteIdeas = () => {
    const { myFavIdeas, user, getText, getIdeas, stage2status } = useAppData()

    const [isLoading, setIsLoading] = useState(false)
    const [selectedType, setSelectedType] = useState(0)

    const handleTypeSelect = e => {
        const { id } = e.target
        setSelectedType(Number(id))
    }

    useEffect(() => {
        const fetchIdeas = async () => {
            setIsLoading(true)
            try {
                await getIdeas(selectedType, true)
            } catch(err) {
                console.log(err)
            }
            setIsLoading(false)
        }
        fetchIdeas()
    }, [selectedType])

    const text = getText('FavouriteIdeas')

    return (
        <PageWrapper footerFaq={text.FAQs}>
            {/* <PageTitle title={text.pageTitle} /> */}
            <div className={styles.root}>
                <p className={styles.subTitle}>{text.subTitle}</p>
                <p className={styles.title}>{text.title}</p>
                <div className={styles.filters}>
                    <div className={selectedType === 0 ? styles.active : ''} id='0' onClick={handleTypeSelect}>All Ideas</div>
                    {MY_IDEAS_FILTERS.map(item => <div className={selectedType === IDEAS_FILTER_VALUES[item] ? styles.active : ''} key={`dating-types-${item}`} id={IDEAS_FILTER_VALUES[item]} onClick={handleTypeSelect}>{item}</div>)}
                </div>
                <div className={styles.mainChallenge}>
                    <div>
                        <img src='/img/neutral.svg' alt=':(' />
                    </div>
                    <div>
                        <span>Main Challenge</span>
                        <p>{stage2status?.challenge?.text}</p>
                    </div>
                </div>
                <div className={styles.content}>
                    {myFavIdeas.length ? <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 1920: 4}}
                    >
                        <Masonry gutter='16px'>
                            {myFavIdeas.map(item => <IdeaCard key={item.id} idea={item} isMe={item.userid === user.id} hideChallenge />)}
                        </Masonry>
                    </ResponsiveMasonry> : <EmptyList text={text.empty} to={ROUTES.IDEATION} />}
                </div>
            </div>
        </PageWrapper>
    )
}

export default FavouriteIdeas