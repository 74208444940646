import React, { useEffect, useState } from 'react'
import { Button, OnboardingCard, PageTitle } from '../../components'
import { useAppData, useModalData } from '../../hooks'
import styles from './OnboardingSelection.module.sass'
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry'
import { ROUTES } from '../../helpers/constants'
import { useNavigate } from 'react-router-dom'

const OnboardingSelection = ({
    type = 0
}) => {
    const {
        getText,
        categories,
        chosenCategories,
        getChallengesList,
        getChosenCategories,
        getOnboardingCategories,
        continueRegisterStep,
        addTerritoryOnboarding,
        getOnboardingStickers,
        setPathname,
        setupChallenge,
        challenges,
        stage
    } = useAppData()
    const navigate = useNavigate()
    const { openModal } = useModalData()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selected, setSelected] = useState([])
    const [stickers, setStickers] = useState([])

    useEffect(() => {
        const fetchStickers = async () => {
            try {
                const { stickers } = await getOnboardingStickers()
                setStickers(prev => [ ...stickers ])
            } catch(err) {
                console.log(err)
            }
        }
        if(!!type) fetchStickers()
    }, [type])

    useEffect(() => {
        if(stage === 1) {
            getOnboardingCategories()
            // getChosenCategories()
        } else if(stage === 2) {
            getChallengesList()
        }
        setPathname(window.location.pathname + 'onboarding')
    }, [])

    // useEffect(() => {
    //     if(!type) setSelected(prev => chosenCategories.map(item => item.id))
    // }, [chosenCategories, type])

    const text = getText(`OnboardingSelection${stage}`)

    const handleSubmit = async e => {
        e.preventDefault()
        setIsSubmitting(true)
        try {
            if(type) navigate(ROUTES.STICKERS + '/new')
            else {
                for(let i in selected) {
                    await addTerritoryOnboarding(selected[i])
                }
                await continueRegisterStep()
            }
        } catch(err) {
            console.log(err)
        }
        setIsSubmitting(false)
    }

    const toggleSelect = e => setSelected(prev => {
        const { id } = e.target
        const index = prev.findIndex(item => id === item)
        if(index > -1) prev.splice(index, 1)
        else prev.push(e.target.id)
        return [ ...prev ]
    })

    const clearSelected = () => setSelected([])

    useEffect(() => {
        if(stage === 2 && selected.length > 0) {
            openModal('alert', {
                ...text.modal,
                onCancel: clearSelected,
                onConfirm: async () => {
                    await setupChallenge(selected[0])
                    await continueRegisterStep()
                }
            })
        }
    }, [stage, selected])

    return (
        <form className={styles.root} onSubmit={handleSubmit}>
            <PageTitle title={text.pageTitle} />
            <div className={styles.content}>
                <h1>{text.title[type]}</h1>
                <p>{text.subTitle[type].split('\n')[0]}</p>
                <p>{text.subTitle[type].split('\n')[1]}</p>
                {text.subTitle[type].split('\n')[2] && <p>{text.subTitle[type].split('\n')[2]}</p>}
                {text.subTitle[type].split('\n')[3] && <p>{text.subTitle[type].split('\n')[3]}</p>}
                {text.subTitle[type].split('\n')[4] && <p>{text.subTitle[type].split('\n')[4]}</p>}
                <div className={styles.button}>
                    {!type && <p>{text.buttonHelper[type]}</p>}
                    {stage !== 2 && <Button type='submit' disabled={!type && (isSubmitting || selected.length < 1)}>{text.button[type]}</Button>}
                </div>
            </div>
            <div className={styles.selection}>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 1, 900: 2, 1920: 4}}
                >
                    <Masonry gutter='16px'>
                        {(stage === 2 ? challenges : type ? stickers : categories).map((item, index) => <OnboardingCard variant={type ? 'sticker' : 'default'} key={`onboardingselection${index}`} content={item} selected={selected.includes(item.id)} toggleSelect={toggleSelect} />)}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </form>
    )
}

export default OnboardingSelection