import { CircularProgress } from '@mui/material'
import React from 'react'
import styles from './FullPageLoader.module.sass'

const FullPageLoader = ({
    active = true
}) => {

    return (
        <div className={`${styles.root} ${active ? styles.active : ''}`}>
            <CircularProgress color='inherit' />
        </div>
    )

}

export default FullPageLoader