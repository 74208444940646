export const ROUTES = {
    ALL: '*',
    HOME: '/',
    DASHBOARD: '/dashboard',
    CATEGORIES: '/categories',
    STICKERS: '/stickers',
    SUPPORT: '/support',
    COCREATING: '/co-creating',
    CHALLENGES: '/challenges',
    IDEATION: '/ideation',
    FAVOURITES: '/favourites',
    DATING: '/dating',

    WELCOME: '/welcome',
    ONBOARDING: '/onboarding'
}

export const STICKER_TYPES_VALUES = {
    Fact: 1,
    Insight: 2,
    Story: 3
}

export const STICKER_TYPES = ['Fact', 'Insight', 'Story']

export const IDEAS_FILTER_VALUES = {
    'Сhallenge as mine': 1,
    NotAsMyChallenge: 2,
    WithComments: 3,
    WithoutComments: 4,
    'More Than One Iteration': 5
}

export const DATING_FILTERS = ['Сhallenge as mine', 'More Than One Iteration']

export const MY_IDEAS_FILTERS = ['More Than One Iteration']

export const MIRO = {
    development: {
        stage1: 'https://miro.com/app/board/uXjVPzxJehw=',
        stage2: ''
    },
    production: {
        stage1: 'https://miro.com/app/board/uXjVPnR1ccI=',
        stage2: ''
    }
}