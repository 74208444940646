import styles from './IdeaCard.module.sass'
import { Avatar } from '..'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../helpers/constants'
import { useAppData } from '../../hooks'

const IdeaCard = ({
    idea,
    isMe,
    hideChallenge = false
}) => {

    const { setIdea } = useAppData()

    const handleSetIdea = () => setIdea(idea)

    return (
        <div className={styles.root}>
            {!hideChallenge && <>
                <div className={styles.challenge}>
                    <p className={styles.title}>The Challenge</p>
                    <p className={styles.subTitle}>{idea.challengetext}</p>
                </div>
                <div className={styles.separator} />
            </>}
            <div className={styles.idea}>
                <div className={styles.avatar}>
                    <Avatar img={idea.useravatar} />
                </div>
                <div className={styles.content}>
                    <p className={styles.title}>The Idea</p>
                    <p className={styles.subTitle}>{idea.text}</p>
                    <div className={styles.cocreators}>
                        <p><strong>{idea.commentsList.length}</strong> Co-Creators</p>
                        <Link to={ROUTES.DATING + `/${idea.id}`}><div>
                            {idea.commentsList.slice(0, 4).map(item => <Avatar key={item.useravatar} img={item.useravatar} />)}
                            {idea.commentsList.length < 4 && !isMe && <Link to={ROUTES.DATING + `/${idea.id}`} onClick={handleSetIdea} className={styles.add}>+</Link>}
                        </div></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IdeaCard