import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Main from './Main'
import './index.sass'

if(window.location.hostname === "ideachain.dobra.world"){
    Sentry.init({
        dsn: "https://0c8e9e5f54f24547bba45a86e8020690@o4504819497041920.ingest.sentry.io/4504819511197696",
        integrations: [new BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Main />
)