import React, { useEffect } from 'react'
import { Button, PageTitle, PageWrapper, StatCard } from '../../components'
import { ROUTES } from '../../helpers/constants'
import useAppData from '../../hooks/useAppData'
import styles from './Dashboard.module.sass'

const STAT_CARDS = [
    ['stickerstypedata', 'stickerstypeinsight', 'stickerstypestory', 'stickers'],
    ['currentmethodology', 'ideas', 'ideasfavorite', 'speeddatings']
]

const Dashboard = () => {
    const { getText, setPathname, stage, updateStatistics } = useAppData()

    const text = getText('Dashboard')[stage - 1]

    useEffect(() => {
        updateStatistics()
    }, [])

    useEffect(() => {
        setPathname(window.location.pathname.includes('dashboard') ? window.location.pathname : window.location.pathname + 'dashboard')
    }, [])

    return (
        <PageWrapper>
        <PageTitle title={text.pageTitle} />
            <div className={styles.root}>
                <p className={styles.subTitle}>{text.subTitle}</p>
                <p className={styles.title}>{text.title}</p>
                <div className={styles.progress}>
                    <p>My progress</p>
                    <div>
                        {STAT_CARDS[stage - 1].map(item => <StatCard key={item} type={item} />)}
                    </div>
                </div>
                <div className={styles.ideation}>
                    <h1>{text.ideation.title}</h1>
                    <p>{text.ideation.subTitle}</p>
                    <Button to={stage === 2 ? ROUTES.IDEATION : ROUTES.STICKERS + '/new'} boxy>{text.ideation.button}</Button>
                </div>
            </div>
        </PageWrapper>
    )
}

export default Dashboard