import React, { useEffect, useState } from 'react'
import useAppData from '../../hooks/useAppData'
import styles from './Challenges.module.sass'
import { OnboardingCard, PageTitle, PageWrapper } from '../../components'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { useModalData } from '../../hooks'

const Challenges = () => {
    const {
        getText,
        challenges,
        setupChallenge,
        getChallengesList,
        setPathname,
        user
    } = useAppData()
    const {
        openModal
    } = useModalData()
    const text = getText('Challenges')

    useEffect(() => {
        getChallengesList()
        setPathname(window.location.pathname)
    }, [])

    const toggleSelect = async e => { 
        try {
            const isChallengeExists = !!user.challengeid
            const { id } = e.target
            openModal(isChallengeExists ? 'delete' : 'alert', {
                ...text.modal[Number(!!user.challengeid)],
                onConfirm: async () => {
                    await setupChallenge(id)
                }
            })
        } catch(err) {
            
        }
    }

    return (
        <PageWrapper footerFaq={text.FAQs}>
            <PageTitle title={text.pageTitle} />
            <div className={styles.root}>
                <p className={styles.subTitle}>{text.subTitle}</p>
                <p className={styles.title}>{text.title}</p>
                <div className={styles.categories}>
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 1920: 4}}
                    >
                        <Masonry gutter='16px'>
                            {challenges.map((item, index) => <OnboardingCard key={`categories${index}`} content={item} selected={item.id === user.challengeid} toggleSelect={toggleSelect} />)}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
        </PageWrapper>
    )
}

export default Challenges