import React from 'react'
import Button from '../Button/Button'
import styles from './OnboardingCard.module.sass'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { STICKER_TYPES } from '../../helpers/constants'

const OnboardingCard = ({
    content,
    toggleSelect,
    selected,
    variant,
    accessor = 'id'
}) => {
    
    return (
        <div className={`${styles.root} ${styles[variant]}`}>
            {content.type && <div id={content.type} className={styles.tag}>{STICKER_TYPES[content.type - 1]}</div>}
            {content.title ? <>
                <p className={styles.title}>{content.title}</p>
                <p className={styles.text}>{content.text}</p>
            </> : <p className={styles.title}>{content.text}</p>}
            <div className={styles.tags}>                
                <Button color={selected ? 'darkblue' : 'cyan'} id={content[accessor]} onClick={toggleSelect}>{selected ? <><CheckCircleOutlineRoundedIcon id={content[accessor]} color='inherit' />Selected</> : variant === 'sticker' ? 'It\'s interesting 👍' : 'Get started'}</Button>
            </div>
        </div>
    )
}

export default OnboardingCard