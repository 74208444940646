import React from 'react'
import { PageWrapper } from '../components'
import { MIRO } from '../helpers/constants'
import { useAppData } from '../hooks'

const CoCreating = () => {
    const { stage } = useAppData()

    return(
        <PageWrapper>
            <iframe id='miro' title='Miro' src={MIRO[process.env.REACT_APP_ENV || 'development'][`stage${stage}`]} />
        </PageWrapper>
    )
}

export default CoCreating