import React, { useState } from 'react'
import { Button, PageTitle } from '../../components'
import useAppData from '../../hooks/useAppData'
import styles from './WelcomeOnBoardPage.module.sass'

const WelcomeOnBoardPage = ({ handleContinue }) => {
    const { user, getText, continueRegisterStep, stage } = useAppData()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const text = getText('WelcomeOnBoardPage')

    const handleSubmit = async e => {
        e.preventDefault()
        setIsSubmitting(true)
        try {
            await continueRegisterStep()
        } catch(err) {
            console.log(err)
        }
        setIsSubmitting(false)
    }

    return (
        <form className={styles.root} onSubmit={handleSubmit}>
            <PageTitle title={text.pageTitle} />
            <h1>{text.welcome + ", "}<span>{user?.fullname}</span>{" " + text.welcome2[stage - 1]}</h1>
            <div className={styles.cards}>
                {text.cards[stage - 1].map((item, index) => <div key={`welcomeonboard-${index}`} className={styles.card}>
                    <div className={styles.img}>{item.img}</div>
                    <label>{item.note}</label>
                    <p>{item.title}</p>
                    <span>{item.content}</span>
                </div>)}
            </div>
            <Button type='submit' disabled={isSubmitting}>{text.button[stage - 1]}</Button>
        </form>
    )
}

export default WelcomeOnBoardPage