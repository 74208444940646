import { isRouteErrorResponse, useRouteError } from "react-router-dom"
import { PageTitle, Button } from "../../components"
import { ROUTES } from "../../helpers/constants"
import useAppData from "../../hooks/useAppData"
import styles from './ErrorRouterPage.module.sass'

const ErrorRouterPage = () => {
    const error = useRouteError()
    const { getText } = useAppData()
    const text = getText('ErrorRouterPage')
    const emailSupport = getText('emailSupport')

    if(isRouteErrorResponse(error) && error.status === 404) return <>
        <PageTitle title={text.e404.pageTitle} />
        <div className={styles.root}>
            <div className={styles.img}>
                <img src='/img/error404.svg' alt='Coming Soon' />
            </div>
            <div className={styles.content}>
                <h1>{text.e404.title}</h1>
                <h3>{text.e404.subTitle}</h3>
                <div className={styles.btns}>
                    <Button to={ROUTES.HOME} boxy><img src='/img/icons/back.svg' alt='+' /><p>{text.e404.button}</p></Button>
                </div>
            </div>
        </div>
        {text.e404.title}
    </>

    if(isRouteErrorResponse(error) && error.status === 401) return <>
        <PageTitle title={text.e401.pageTitle} />
        <div className={styles.root}>
            <div className={styles.img}>
                <img src='/img/error404.svg' alt='Coming Soon' />
            </div>
            <div className={styles.content}>
                <h1>{text.e401.title}</h1>
                <h3>{text.e401.subTitle}</h3>
                <div className={styles.btns}>
                    <Button to={`mailto:${emailSupport}`} rel="noreferrer" target='_blank' boxy><img src='/img/icons/back.svg' alt='+' /><p>{text.e401.button}</p></Button>
                </div>
            </div>
        </div>
        {text.e404.title}
    </>

    return <>
        <PageTitle title={text.e.pageTitle} />
        <div className={styles.root}>
            <div className={styles.img}>
                <img src={`/img/comingSoonBG.svg`} alt='Coming Soon' />
            </div>
            <div className={styles.content}>
                <h1>{text.e.title}</h1>
                <h3>{text.e.subTitle}</h3>
                <div className={styles.btns}>
                    <Button to={ROUTES.HOME} boxy><img src='/img/icons/mail.svg' alt='+' /><p>{text.e.button}</p></Button>
                </div>
            </div>
        </div>
        {text.e.title}
    </>
}

export default ErrorRouterPage