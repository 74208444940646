import styles from './Avatar.module.sass'

const Avatar = ({
    img,
    me = false
}) => {

    return (
        <div className={`${styles.root} ${me ? styles.me : ''}`}>
            <div>
                <img src={img} alt='avatar' />
            </div>
        </div>
    )
}

export default Avatar