import styles from './EmptyList.module.sass'
import { Button } from '..'

const EmptyList = ({
    text,
    onClick,
    to
}) => (
    <div className={styles.root}>
        <p className={styles.title}>{text.title}</p>
        <p className={styles.subTitle}>{text.subTitle}</p>
        <Button className={styles.btn} to={to} onClick={onClick}>{text.button}</Button>
    </div>
)

export default EmptyList