import React, { useEffect, useState } from 'react'
import styles from './Stickers.module.sass'
import { Button, PageTitle, PageWrapper, Sticker } from '../../components'
import useAppData from '../../hooks/useAppData'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import { ROUTES, STICKER_TYPES, STICKER_TYPES_VALUES } from '../../helpers/constants'
import { IconButton, Menu, MenuItem } from '@mui/material'

const Stickers = () => {
    const { user, getText, getChosenCategories, chosenCategories, getMyStickers, stickers, setPathname } = useAppData()

    const [isLoading, setIsLoading] = useState(false)
    const [selectedType, setSelectedType] = useState(0)

    useEffect(() => {
        const fetchMyStickers = async () => {
            setIsLoading(true)
            try {
                await getMyStickers()
            } catch(err) {
                console.log(err)
            }
            setIsLoading(false)
        }
        fetchMyStickers()
        getChosenCategories()
    }, [])

    const handleTypeSelect = e => {
        const { id } = e.target
        setSelectedType(Number(id))
    }

    const text = getText('Stickers')

    useEffect(() => {
        setPathname(window.location.pathname)
    }, [])

    return (
        <PageWrapper>
            <PageTitle title={text.pageTitle} />
            <div className={styles.root}>
                <p className={styles.subTitle}>{text.subTitle}</p>
                <p className={styles.title}>{text.title}</p>
                <div className={styles.filters}>
                    <div className={selectedType === 0 ? styles.active : ''} id='0' onClick={handleTypeSelect}>All Types</div>
                    {STICKER_TYPES.map(item => <div className={selectedType === STICKER_TYPES_VALUES[item] ? styles.active : ''} key={`stickers-types-${item}`} id={STICKER_TYPES_VALUES[item]} onClick={handleTypeSelect}>{item}</div>)}
                </div>
                <div className={styles.content}>
                    
                    {chosenCategories.map((category, index) => <div key={`chosenCategories${index}`} className={styles.category}>
                        <div className={styles.intro}>
                            <div>
                                <p className={styles.subTitle}>{text.subTitle}</p>
                                <p className={styles.title}>{category.title}</p>
                            </div>
                            <div>
                                <Button to={`${ROUTES.STICKERS}/new`} state={{ chosenCategory: category.id }}><AddCircleRoundedIcon color='inherit' /><p>Add</p></Button>
                            </div>
                        </div>
                        <div className={styles.cards}>
                            {stickers.filter(item => !selectedType || item.type === selectedType).filter(item => item.territoryid === category.id).length
                                ? 
                                stickers.filter(item => !selectedType || item.type === selectedType).filter(item => item.territoryid === category.id).map((item, index) =>
                                    <Sticker key={`stickers-sticker-${index}`} me={user} sticker={item} handleTypeSelect={() => {}} />
                                )
                                :
                                <div className={styles.addCard}>
                                    <p>Let's create your first sticker</p>
                                    <Button to={`${ROUTES.STICKERS}/new`} state={{ chosenCategory: category.id }}>Create Sticker</Button>    
                                </div>
                            }
                        </div>
                    </div>)}

                </div>
            </div>
        </PageWrapper>
    )
}

export default Stickers