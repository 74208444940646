import React, { useEffect } from 'react'
import styles from './LogInPage.module.sass'
import useAppData from '../../hooks/useAppData'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { firebaseApp, uiConfig } from '../../Main'
import { PageTitle } from '../../components'

const LogInPage = () => {
    const { getText, setPathname } = useAppData()
    const text = getText('LogInPage')

    useEffect(() => {
        setPathname(window.location.pathname + 'login')
    }, [])


    return (
        <>
            <PageTitle title={text.pageTitle} />
            <div className={styles.root}>
                <div className={styles.img}>                    
                    <img src='/img/IdeaChainLogin.svg' alt='Log In' />                    
                </div>
                <div className={styles.content}>
                    <h1>{text.title}</h1>
                    <p>{text.description}</p>
                    <h3>{text.subTitle}</h3>                    
                    <div className={styles.uiFirebase}><StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseApp.auth()}/></div>
                </div>
            </div>
        </>
    )
}

export default LogInPage