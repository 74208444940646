import React, { useEffect, useMemo } from "react"
import { FullPageLoader, Modal } from "./components"
import useAppData from "./hooks/useAppData"
import { motion, AnimatePresence } from 'framer-motion'
import ComingSoonPage from "./pages/ComingSoonPage/ComingSoonPage"
import LogInPage from "./pages/LogInPage/LogInPage"
import WelcomeOnBoardPage from "./pages/WelcomeOnBoardPage/WelcomeOnBoardPage"
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import OnboardingSelection from "./pages/OnboardingSelection/OnboardingSelection"
import Dashboard from "./pages/Dashboard/Dashboard"
import { ROUTES } from "./helpers/constants"
import Categories from "./pages/Categories/Categories"
import Stickers from "./pages/Stickers/Stickers"
import StickerPage from "./pages/StickerPage/StickerPage"
import CoCreating from "./pages/CoCreating"
import ErrorRouterPage from "./pages/ErrorRouterPage/ErrorRouterPage"
import FavouriteIdeas from "./pages/FavouriteIdeas/FavouriteIdeas"
import IdeaSpeedDating from "./pages/IdeaSpeedDating/IdeaSpeedDating"
import IdeaSpeedDatingPage from "./pages/IdeaSpeedDatingPage/IdeaSpeedDatingPage"
import Challenges from "./pages/Challenges/Challenges"
import Ideation from "./pages/Ideation/Ideation"
import CreateIdea from "./pages/CreateIdea"

const App = () => {
    const {
        stage,
        getSecondStageStatus,
        getIdeas,
        isAppLoading,
        firebaseUiStarted,
        isAppBlocked,
        user,
        continueRegisterStep,
        methodology,
        stage2status,
        getMethodology
    } = useAppData()

    const router = useMemo(() => !isAppLoading && createBrowserRouter(firebaseUiStarted ? [
        {
            path: ROUTES.ALL,
            element: <LogInPage />,
            errorElement: <ErrorRouterPage />
        }
    ] : !user?.registerStep ? [
        {
            path: ROUTES.WELCOME,
            element: <WelcomeOnBoardPage handleContinue={continueRegisterStep} />,
            errorElement: <ErrorRouterPage />
        },
        {
            path: ROUTES.ALL,
            element: <><Navigate to={ROUTES.WELCOME} /></>,
            errorElement: <ErrorRouterPage />
        }
    ] : (user?.registerStep === 1 || (stage === 2 && !user.challengeid)) ? [
        {
            path: ROUTES.ONBOARDING,
            errorElement: <ErrorRouterPage />,
            children: [
                {
                    index: true,
                    element: <OnboardingSelection handleContinue={continueRegisterStep} />
                }
            ]
        },
        {
            path: ROUTES.ALL,
            element: <><Navigate to={ROUTES.ONBOARDING} /></>,
            errorElement: <ErrorRouterPage />
        }
    ] : [
        {
            path: ROUTES.ONBOARDING,
            element:  <><Navigate to={stage === 1 ? `${ROUTES.STICKERS}/new` : stage === 2 && ROUTES.IDEATION} /></>,
            errorElement: <ErrorRouterPage />
        },
        ...(stage === 1 ? [
            {
                path: ROUTES.CATEGORIES,
                element: <Categories />,
                errorElement: <ErrorRouterPage />
            },
            {
                path: ROUTES.STICKERS,
                children: [
                    {
                        index: true,
                        element: <Stickers />
                    },
                    {
                        path: 'examples',
                        element: <OnboardingSelection handleContinue={continueRegisterStep} type={1} />
                    },
                    {
                        path: ':id',
                        element: <StickerPage />
                    }
                ],
                errorElement: <ErrorRouterPage />
            }
        ] : []),
        ...(stage === 2 ? [
            {
                path: ROUTES.CHALLENGES,
                element: <Challenges />,
                errorElement: <ErrorRouterPage />
            },
            {
                path: ROUTES.DATING,
                children: [
                    {
                        index: true,
                        element: <IdeaSpeedDating />
                    },
                    {
                        path: ':id',
                        element: <IdeaSpeedDatingPage />
                    }
                ],
                errorElement: <ErrorRouterPage />
            },
            {
                path: ROUTES.FAVOURITES,
                element: <FavouriteIdeas />,
                errorElement: <ErrorRouterPage />
            },
            {
                path: ROUTES.IDEATION,
                element: <Ideation />,
                errorElement: <ErrorRouterPage />
            }
        ] : []),
        {
            path: ROUTES.HOME,
            element: <Dashboard />,
            errorElement: <ErrorRouterPage />
        },
        {
            path: ROUTES.DASHBOARD,
            element: <Dashboard />,
            errorElement: <ErrorRouterPage />
        },
        
        {
            path: ROUTES.COCREATING,
            element: <CoCreating />,
            errorElement: <ErrorRouterPage />
        },
        {
            path: '/create',
            element: <CreateIdea />,
            errorElement: <ErrorRouterPage />
        }
    ]), [isAppLoading, firebaseUiStarted, user?.challengeid, user?.registerStep, continueRegisterStep, stage])

    useEffect(() => {
        if(stage === 2 && user) getSecondStageStatus(true)
    }, [stage, user])

    useEffect(() => {
        if(user) getIdeas(true)
    }, [user])

    useEffect(() => {
        if(stage2status && stage2status.currentmethodologyid && !methodology) getMethodology(stage2status.currentmethodologyid)
    }, [stage2status, methodology])

    return (
        <AnimatePresence>
            {typeof(isAppBlocked) !== 'boolean' ? null
            : isAppBlocked ? <ComingSoonPage />
            : isAppLoading ? <motion.div key='loader' exit={{ opacity: 0 }}><FullPageLoader /></motion.div>
            : <RouterProvider router={router} />
            }
            <Modal key='modal' />
        </AnimatePresence>
    )
}

export default App