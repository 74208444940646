import { IconButton, Menu, MenuItem } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES, STICKER_TYPES } from '../../helpers/constants'
import styles from './Sticker.module.sass'
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded'

const Sticker = ({
    sticker,
    me,
    handleTypeSelect
}) => {
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    
    const handleClick = e => {
      setAnchorEl(e.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    const handleEdit = () => navigate(ROUTES.STICKERS + '/' + sticker.id)


    return (
        <div className={styles.card}>
            <div className={styles.profile}>
                <div className={styles.avatar}>
                    <img src={me.avatar} alt='avatar' />
                </div>
                <div className={styles.info}>
                    <p>{me.fullname}</p>
                    <span>Owner</span>
                </div>
                <div className={styles.menu}>
                    <IconButton
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreHorizRoundedIcon color='inherit' />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        {me.id === sticker.userid && <MenuItem id={sticker.id} onClick={handleEdit}>
                            Edit
                        </MenuItem>}
                    </Menu>
                </div>
            </div>
            <div className={styles.content}>
                <p className={styles.title}>{sticker.title}</p>
                <p className={styles.text}>{sticker.text}</p>
            </div>
            <div className={styles.tags}>
                <div id={sticker.type} onClick={handleTypeSelect} className={styles.tag}>{STICKER_TYPES[sticker.type - 1]}</div>
            </div>
        </div>
    )
}

export default Sticker