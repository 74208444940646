export function simplifyTerritoryList(arr) {
    let res = []
    arr.forEach(item => {
        res.push(item.toObject())
    })
    return res
}

export function simplifyStickersList(arr) {
    let res = []
    arr.forEach(item => {
        res.push(item.toObject())
    })
    return res
}