import { useAppData } from '../../hooks'
import styles from './StatCard.module.sass'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined'
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded'
import SwapHorizontalCircleRoundedIcon from '@mui/icons-material/SwapHorizontalCircleRounded'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded'
import { useEffect, useMemo } from 'react'

const LABELS = {
    currentmethodology: 'Ideation Methodology',
    ideas: 'Generated Ideas',
    ideasfavorite: 'Favourite Ideas',
    speeddatings: 'Speed Dating',
    stickers: 'Stickers added',
    stickerstypedata: 'Fact or Data',
    stickerstypeinsight: 'Insight',
    stickerstypestory: 'User Story'
}

const StatCard = ({
    type
}) => {
    const { getText, stage, user: { counters }, stage2status } = useAppData()
    const _max = getText('StatCard')

    const currentmethodology = useMemo(() => stage === 2 ? stage2status.currentmethodology - 1 : 0, [stage, stage2status])
    const max = useMemo(() => type === 'currentmethodology' ? stage2status.totalmethodologies : _max[stage - 1][type], [_max, stage, type, stage2status])
    const count = useMemo(() => type === 'currentmethodology' ? currentmethodology : counters[type], [type, currentmethodology, counters])

    const Icon = () => {
        switch (type) {
            case 'currentmethodology':
                return <TimerOutlinedIcon color='inherit' />
            case 'ideas':
                return <LightbulbOutlinedIcon color='inherit' />
            case 'ideasfavorite':
                return <FavoriteRoundedIcon color='inherit' />
            case 'speeddatings':
                return <SwapHorizontalCircleRoundedIcon color='inherit' />
            case 'stickers':
                return <TimerOutlinedIcon color='inherit' />
            case 'stickerstypedata':
                return <FactCheckOutlinedIcon color='inherit' />
            case 'stickerstypeinsight':
                return <LightbulbOutlinedIcon color='inherit' />
            case 'stickerstypestory':
                return <StarRateRoundedIcon color='inherit' />
            default:
                return <></>
        }
    }

    return (
        <div className={`${styles.root} ${styles[type]}`}>
            <div className={styles.icon}>
                <Icon />
            </div>
            <div className={styles.content}>
                <div className={styles.count}>{count}{max && max >= count ? ` / ${max}` : null}</div>
                <p className={styles.type}>{LABELS[type]}</p>
                {max && max >= count ? <div className={styles.progress}><div style={{ width: `${(count / max) * 100}%` }} /></div> : null}
            </div>
        </div>
    )
}

export default StatCard