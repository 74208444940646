import styles from './IdeaSpeedDating.module.sass'
import { Button, EmptyList, IdeaCard, PageTitle, PageWrapper } from "../../components"
import { useAppData } from "../../hooks"
import { useCallback, useEffect, useState } from 'react'
import { DATING_FILTERS, IDEAS_FILTER_VALUES, ROUTES } from '../../helpers/constants'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

const IdeaSpeedDating = () => {
    const { getText, getIdeas, ideas, stage2status } = useAppData()

    const [isLoading, setIsLoading] = useState(false)
    const [selectedType, setSelectedType] = useState(0)

    const handleTypeSelect = e => {
        const { id } = e.target
        setSelectedType(Number(id))
    }

    useEffect(() => {
        const fetchIdeas = async () => {
            setIsLoading(true)
            try {
                await getIdeas(selectedType)
            } catch(err) {
                console.log(err)
            }
            setIsLoading(false)
        }
        fetchIdeas()
    }, [selectedType])

    const fetchIdeas = useCallback(async () => {
        setIsLoading(true)
        try {
            await getIdeas(selectedType)
        } catch(err) {
            console.log(err)
        }
        setIsLoading(false)
    }, [selectedType, setIsLoading, getIdeas])

    const text = getText('IdeaSpeedDating')

    return (
        <PageWrapper footerFaq={text.FAQs}>
            <PageTitle title={text.pageTitle} />
            <div className={styles.root}>
                <p className={styles.subTitle}>{text.subTitle}</p>
                <p className={styles.title}>{text.title}</p>
                <div className={styles.filtersWrapper}>
                    <div className={styles.filters}>
                        <div className={selectedType === 0 ? styles.active : ''} id='0' onClick={handleTypeSelect}>All Ideas</div>
                        {DATING_FILTERS.map(item => <div className={selectedType === IDEAS_FILTER_VALUES[item] ? styles.active : ''} key={`dating-types-${item}`} id={IDEAS_FILTER_VALUES[item]} onClick={handleTypeSelect}>{item}</div>)}
                    </div>
                    <Button onClick={fetchIdeas} disabled={isLoading} color="transparent"><AutorenewRoundedIcon className={isLoading ? 'rotate' : ''} color='inherit' />Get More</Button>
                </div>
                <div className={styles.content}>
                    {stage2status.isspeeddatingenabled ? ideas.length ? <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 1, 1280: 2, 1920: 4}}
                    >
                        <Masonry gutter='16px'>
                            {ideas.map(item => <IdeaCard key={`idea-dating-${item.id}`} idea={item} />)}
                        </Masonry>
                    </ResponsiveMasonry> : <EmptyList text={text.empty} to={ROUTES.IDEATION} /> : <EmptyList text={text.notAllowed} to={ROUTES.IDEATION} />}
                </div>
            </div>
        </PageWrapper>
    )
}

export default IdeaSpeedDating