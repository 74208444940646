import { useCallback, useContext } from "react"
import { initialModalDataState } from "../helpers/initialState"
import { ModalDataContext } from "../Main"

// title
// subTitle
// onConfirm - confirm callback
// confirm - string for buttons
// onCancel - cancel callback
// cancel - string for buttons


const useModalData = () => {
    const [modalData, setModalData] = useContext(ModalDataContext)

    const toggleOpenModal = useCallback(() => setModalData(prev => ({...prev, open: !prev.open})), [setModalData])

    const openModal = useCallback((type, data) => setModalData(prev => ({
        ...prev,
        open: true,
        type,
        data: {
            ...prev.data,
            ...data
        }
    })), [setModalData])

    const clearModal = useCallback(() => setModalData(initialModalDataState), [setModalData])

    return {
        ...modalData,
        toggleOpenModal,
        openModal,
        clearModal
    }
}

export default useModalData