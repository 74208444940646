export const initialAppDataState = {
    isAppLoading: true,
    isLoading: false,
    user: null,
    timeBeforeStart: null,
    firebaseUiStarted: false,
    isAppBlocked: null,
    stage: null,
    categories: [],
    stickers: [],
    challenges: [],
    chosenCategories: [],
    pathname: null,
    stage2status: null,
    myFavIdeas: [],
    idea: null,
    ideas: [],
    methodology: null,
    statistics: {}
}

export const initialModalDataState = {
    open: false,
    type: null,
    data: {
        title: 'Alert',
        subTitle: 'Listen!',
        onConfirm: null,
        onCancel: null,
        errorHandler: null,
        confirm: 'OK',
        cancel: 'Cancel'
    }
}